.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
} 


.search-btn {
  align-items: center;
  background: linear-gradient(180deg, rgb(178, 0, 207) 13.23%, rgb(46, 92, 255) 84.65%);
  border-radius: 48px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.5px;
  justify-content: center;
  min-width: 70px;
  padding: 5px 9.24px 4.5px 9.24px;
  position: relative;
}

.search-btn .text-wrapper-2 {
  color: #f8f8f8;
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
