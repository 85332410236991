.openai-response-container {
  position: absolute;
  right: 5%;
  top: 150px;
  width: 30%;
  padding: 20px;
  background-color: #ffffff; /* White background for a cleaner look */
  border-radius: 10px; /* Increased border radius for softer edges */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Softer shadow for depth */
  overflow: auto;
  font-family: 'Arial', sans-serif; /* Modern font choice */
  color: #333333; /* Darker text color for contrast */
  line-height: 1.5; /* Improved readability */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.openai-response-container h3 {
  color: #1A73E8; /* Blue color for headings to add visual interest */
  margin-bottom: 10px; /* Spacing between heading and content */
}

.openai-response-container:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}


.mobile-openai-response {
  margin-top: 150px;
}

.mobile-openai-response button {
  background: #ffffff; /* White background for consistency */
  border: 1px solid #e0e0e0;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-family: 'Arial', sans-serif; /* Modern font choice */
  color: #333333; /* Darker text color for contrast */
  border-radius: 10px; /* Soft edges */
  transition: all 0.3s ease; /* Smooth transition */
}

.mobile-openai-response button:hover {
  background: #f5f5f5; /* Slight background color change on hover */
}

#mobileOpenAIResponseContent {
  display: none; /* Initially hidden */
  padding: 20px; /* Increased padding */
  background: #ffffff; /* White background for consistency */
  border: 1px solid #e0e0e0;
  border-radius: 10px; /* Soft edges */
  font-family: 'Arial', sans-serif; /* Modern font choice */
  color: #333333; /* Darker text color for contrast */
  line-height: 1.5; /* Improved readability */
}

/* Hide the existing OpenAI response section on smaller screens */
@media (max-width: 767px) {
  .openai-response-container {
    display: none;
  }
}

/* Show the collapsible section on smaller screens and hide it on larger screens */
@media (min-width: 768px) {
  #mobileOpenAIResponse {
    display: none;
  }
}
